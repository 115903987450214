.catalog-stripe-sidebar-mob-container {
    display: none;
}

.sidebar-mob-hidden {
    display: none;
}

.button-catalog-mob {
    width: 100%;
    background-color: rgba(114, 191, 68, 1);
}

@media screen and (width <= 1000px) {
    .catalog-stripe-sidebar-mob-container {
        display: block !important;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.2);
        left: 0;
        right: 0;
        left: 0;
        top: 0;
        z-index: 20;
    }
}
