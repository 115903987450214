.input-title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.78px;
    text-align: left;
    color: rgba(69, 69, 69, 1);
    padding-left: 17px;
}

.modal-input {
    border: 1px solid rgba(114, 191, 68, 1);
    border-left-width: 5px;
    border-radius: 7px;
    width: 100%;
    padding: 10px;
}

@media (width <= 1000px) {
    .modal-input {
        padding: 6px;
    }
}
