@tailwind utilities;

.comment {
    padding-top: 30px;
    width: 60%;
}

.comment-avatar {
    width: 55px;
    height: 55px;
    background: #d9d9d9;
    border-radius: 50%;
}

.comment-username {
    height: 100%;
    align-self: flex-start;
    padding-left: 8px;
    padding-right: 20px;
}

.comment-text {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 300;
    line-height: 12.91px;
    text-align: left;
    color: #454545;
}

@media (width <= 1000px) {
    .comment {
        padding-top: 30px;
        width: 100% !important;
    }
}
