.admin-main-container {
    margin: 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.admin-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #f1f1f1;
}