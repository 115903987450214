.plus-icon {
  width: 32px;
  height: 32px;
  fill: #C7C7C7;
  cursor: pointer;
  transition: fill 0.3s ease;
}

.plus-icon:hover {
  fill: #72BF44;
}
