@tailwind utilities;

svg {
    color: #ef4444;
}

.arrows {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    align-items: center;
}

@media (width <= 1000px) {
    .arrows {
        display: none;
    }
}
