@tailwind utilities;

.section-title {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.18px;
    text-align: left;
    color: #454545;
}

.cards {
    display: grid;
}
@media (min-width: 900px) {
    .cards {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (width <= 900px) {
    .cards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (width <= 700px) {
    .cards {
        grid-template-columns: repeat(1, 2fr);
    }
}

@media (width <= 400px) {
    .cards {
        grid-template-columns: repeat(1, 2fr);
    }
}
