.tags-custom-input {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: white;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
}

.tags-custom-input:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: inset 0px 0px 5px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: inset 0px 0px 5px 2px rgba(0,0,0,0.1);
    box-shadow: inset 0px 0px 5px 2px rgba(0,0,0,0.1);
}