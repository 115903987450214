.product-main-container {
    width: 100%;
    margin-left: 2.5rem;
}

@media (width <= 1000px) {
    .product-main-container {
        margin: 0 0.6rem !important;
    }
}
