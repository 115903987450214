.total-price-container {
    border: 1px solid #72bf44;
    border-left-width: 5px;
    min-width: 30%;
    width: 50%;
}

.total-price-title {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.48px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #c7c7c7;
}

.total-price-value {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 600;
    line-height: 43.88px;
    text-align: left;
    color: #454545;
}

.total-price-value-currency {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.18px;
    text-align: left;
    color: #454545;
}

@media (width <= 1000px) {
    .total-price-container {
        width: 100% !important;
    }
    .total-price-value {
        font-size: 32px !important;
        font-weight: 600 !important;
        line-height: 39.01px !important;
        text-align: left !important;
    }
    .total-price-value-currency {
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 19.5px !important;
        text-align: left !important;
    }
}
