@tailwind utilities;

.category-name {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.18px;
    text-align: left;
    color: #454545;
}

.product-view-container {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.product-avatar {
    width: 70%;
}

.product-title {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 700;
    line-height: 23.48px;
    text-align: left;
    padding-bottom: 30px;
}

.product-description {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    margin-bottom: 30px;
}

.product-description li {
    margin-bottom: 15px;
}

.product-description li::before {
    content: '━';
    padding-right: 10px;
}

.product-price-old {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.78px;
    text-align: left;
    text-decoration: line-through;
    color: #c7c7c7;
}

.product-price-main {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 700;
    line-height: 39.01px;
    text-align: left;
    color: #51c72f;
}

.product-info {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.44px;
    text-align: left;
    color: #454545;
}

.product-current-currency {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 400;
    line-height: 39.01px;
    text-align: left;
    color: rgba(114, 191, 68, 1);
}

@media (width <= 1000px) {
    .product-avatar {
        justify-self: center;
    }
}
