nav > a {
    color: rgba(69, 69, 69, 1);
    font-size: 14px;
    font-weight: 600;
    padding-left: 40px;
}

.upper-header {
    justify-content: unset;
}

@media (width <= 1000px) {
    .upper-header-button-container {
        display: none !important;
    }

    .upper-header {
        justify-content: center !important;
        padding: 10px 0;
    }
}
