.table-category-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px 3px 3px 3px;
    color: #fff;
    display: inline-block;
    margin: 2px 0;
    padding: 3px 5px;
    background-color: #019bd7;
}

.table-category-tag a {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
}