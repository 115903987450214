@tailwind utilities;

.footer-main-container {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(114, 191, 68, 1);
    padding: 30px 0;
}

.footer-inner-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

p {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 800;
    line-height: 23.48px;
    letter-spacing: 0.03em;
    text-align: center;
    color: white;
}

.footer-contacts {
    position: absolute;
    right: 50px;
    display: flex;
    flex-direction: column;
}

.footer-contacts > p {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    letter-spacing: 0.03em;
    text-align: center;
}

.footer-bottom-text {
    padding-top: 30px;
}

.footer-bottom-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    gap: 20px;
}

.language-selector-container {
    position: absolute;
    right: 2%;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

@media (width <= 1000px) {
    .footer-inner-container {
        flex-direction: column-reverse !important;
        gap: 10px;
    }
    .footer-contacts {
        position: relative !important;
        right: 0 !important;
        left: 0 !important;
        gap: 10px;
    }
    .footer-bottom-text {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 800;
        line-height: 16.44px;
        letter-spacing: 0.03em;
        text-align: center;
    }
    .language-selector-container {
        position: relative !important;
        display: block !important;
        width: fit-content !important;
    }
}
