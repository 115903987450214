@tailwind utilities;

.card {
    padding: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: #ebeff1;
    border-top-color: #fff;
    border-left-color: #fff;
    display: grid;
}

.card:hover {
    border: 1px solid #51c72f;
}

.card img {
    width: 100%;
}

.card-title {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.44px;
    text-align: left;
    color: #454545;
}

.card-description {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: left;
    color: #454545;
    padding-top: 10px;
    padding-bottom: 10px;
}

.card-price-old {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    text-decoration-line: line-through;
    color: #c7c7c7;
}

.card-price-main {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    text-align: left;
    color: #51c72f;
}

.small-text {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.19px;
    text-align: right;
    color: #c7c7c7;
}
