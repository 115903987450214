.cart-title {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.18px;
    text-align: left;
    color: #454545;
}

.cart-main-container {
    width: 100%;
    margin-left: 2.5rem;
}

.catalog-stripe-mob-container {
    display: none;
}

.cart-total-price-container {
    display: flex;
    width: 100%;
    padding-top: 2.5rem;
    justify-content: end;
}

@media (width <= 1000px) {
    .cart-main-container {
        margin-left: 0;
    }
    .catalog-stripe-mob-container {
        display: block !important;
    }
}
