@tailwind utilities;

.section-container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.user-info-logout-botton-mob {
    display: none;
}

.user-info-info-section {
    display: block;
}

.user-info-info-section-mob {
    display: none;
}

@media screen and (max-width: 1000px) {
    .product-section-container {
        display: flex !important;
        flex-direction: column;
        row-gap: 1rem !important;
    }
    .user-info-logout-botton-mob {
        display: flex;
    }
    .user-info-info-section {
        display: none;
    }
    .user-info-info-section-mob {
        display: block;
    }
    .user-info-info-section-mob > h1 {
        justify-content: end;
        display: flex;
    }
}
