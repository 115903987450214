@tailwind utilities;

.comments-title {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.48px;
    text-align: center;
    color: #454545;
}

.small-text {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 12.19px;
    text-align: right;
    color: #c7c7c7;
}
