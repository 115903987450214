@tailwind utilities;

@media (width <= 1000px) {
    /* .sidebar-mob-close {
        display: none !important;
    } */
    .sidebar {
        width: 100% !important;
        display: none !important;
        background-color: white;
    }
}

.sidebar-mob-open {
    display: flex !important;
}

.sidebar {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: fit-content;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: rgba(235, 239, 241, 1);
    padding-right: 0;
}

.subcategory-list {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: rgba(235, 239, 241, 1);
}

.category-title {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.44px;
    text-align: left;
    color: rgba(81, 199, 47, 1);
    padding: 10px;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: rgba(235, 239, 241, 1);
    padding: 20px;
    padding-right: 0;
}

.subcategory-title {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: rgba(69, 69, 69, 1);
    padding: 5px;
    padding-left: 10px;
}

.subcategory-title:hover {
    cursor: pointer;
    background: rgba(81, 199, 47, 1);
    color: rgba(255, 255, 255, 1);
}

.sidebar-header-mob-title {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.44px;
    text-align: left;
    color: rgba(114, 191, 68, 1);
}
