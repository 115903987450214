@tailwind utilities;

.upper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.upper-header-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-title {
    font-size: 10px;
    font-weight: 400;
    font-style: Raleway;
    color: #51c72f;
}

.header-nav-links-mob {
    display: none;
}

/* Bottom stripe */
.header-bottom-stipe-mob {
    display: none;
}
.bottom-stripe-guest {
    background-color: rgba(114, 191, 68, 0.25);
    border: 1px solid rgba(114, 191, 68, 1);
}
.bottom-stripe-authed {
    background-color: rgba(255, 65, 65, 0.1);
    border: 1px solid rgba(255, 65, 65, 1);
}

@media (width <= 1000px) {
    .header-nav-links {
        display: none;
    }
    .header-nav-links-mob {
        display: flex;
    }
    .header-upper-container {
        display: none;
    }
    .header-bottom-stipe-mob {
        display: flex;
        padding: 8.5px 0;
        margin: 10px 2%;
        border-radius: 7px;
        justify-content: center;
        align-items: center;
    }
    .header-bottom-stripe-mob-text {
        font-family: Raleway;
        font-size: 16px;
        font-weight: 600;
        line-height: 18.78px;
        text-align: center;
        color: rgba(255, 65, 65, 1);
    }
}

@media (width <= 500px) {
    .header-input {
        width: 100px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 22px 10px;
    }
    .header-input-button {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 22px 10px !important;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}

.header-stripe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    background: #fafafa;
    border: 0.5px solid #ebeff1;
}

.header-logo-navigation-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Input */
.header-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-input {
    background-color: #51c72f40;
    color: rgba(69, 69, 69, 1);
    font-size: 10px;
    font-weight: 400;
    height: 25px;
    font-style: Raleway;
    border: none;
    outline: none;
    padding-left: 8px;
}
.header-input-button {
    background-color: #51c72f40;
    height: 25px;
    padding: 0 8px;
}
