@tailwind utilities;

.like-container {
    width: 100%;
    margin-left: 2.5rem;
}

.catalog-stripe-mob-container {
    display: none;
}

@media screen and (max-width: 1000px) {
    .like-container {
        margin: 0;
    }
    .catalog-stripe-mob-container {
        display: block !important;
    }
}
