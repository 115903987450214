.tags-list-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-height: 10.5rem;
    background-color: white;
    overflow-y: scroll;
    gap: 5px;
    padding: 15px;
    -webkit-box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.1);
    z-index: 9999;
}

.tag:hover {
    cursor: pointer;
    h2 {
        text-decoration: underline;
    }
}

.tag-selected {
    text-decoration: underline;
}