.editor-toolbar {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 10px;
    width: 100%;
}

.editor-main-container {
    /* border: 1px solid gray; */
    min-height: 6em;
    border-radius: 5px;
    /* padding: 7px 15px; */
}

.editor-inner-continer {
    margin-top: 10px;
    word-break: break-all;
}

.toolbar-button {
    color: black;
}

.toolbar-button.selected {
    color: #5890ff;
}