@tailwind utilities;

/* @layer utilities {
    .section-border {
        @apply ;
    }
} */

.section {
    border-width: 1px;
    border-left-width: 5px;
    border-style: solid;
    border-color: rgba(81, 199, 47, 1);
    border-radius: 7px;

    padding: 10px 20px;
    padding-right: 0;
    width: 100%;

    cursor: pointer;
}

.title {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.78px;
    text-align: left;
    color: rgba(69, 69, 69, 1);
}

.content-text {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.18px;
    text-align: left;
    color: rgba(69, 69, 69, 1);
}

.content-text-mob {
    display: none;
}

.section-button-pc {
    display: block;
}

.section-button-mob {
    display: none;
}

@media screen and (max-width: 1000px) {
    .section {
        padding: 20px;
        gap: 10px;
        padding-right: 0;
    }
    .title {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.44px;
        text-align: left;
        color: rgba(69, 69, 69, 1);
    }
    .content-text {
        display: none;
    }
    .content-text-mob {
        display: block;
        color: rgba(69, 69, 69, 1);
        font-family: Raleway;
        font-size: 16px;
        font-weight: 700;
        line-height: 18.78px;
        text-align: left;
    }
    .section-button-pc {
        display: none;
    }
    .section-button-mob {
        display: block;
    }
}
